(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:ChampionshipMatches
   *
   * @description
   *
   */
  angular
      .module('neo.services')
      .factory('ChampionshipMatches', ChampionshipMatches);

  function ChampionshipMatches($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/championships/:id/matches', {id: '@_id'}, {
      update: {method: 'PATCH'},
      replace: {method: 'PUT'}
    });
  }
}());
